.add-gateway-preloader-container,
.installedsm-delete-prealoder-container {
  height: 185px;
  display: flex;
  align-items: center;
}

.installed-sm-expander-filters {
  row-gap: 10px;
  column-gap: 50px;
  background-color: #EFF0EF;
}

.version-field {
  min-width: 250px;
}

.firmware-container {
  max-width: 770px;
  margin-bottom: 15px;
}

.row-gap-5 {
  row-gap: 5px;
}

.column-gap-10 {
  column-gap: 10px;
}

.modal-body-gateway {
  padding-top: 25px !important;
}

.name-or-smid-note {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #000;
  border: 1px solid #8D968D;
  border-radius: 8px;
  background-color: #fff;
}

.confirmation-field {
  margin: 0 auto;
  padding: 0 50px;
}

.confirmation-field>.customTextField {
  width: 100%;
}

.confirmation-field>.customTextField>label {
  padding-left: 10px;
  font-size: 16px;
}

.confirmation-field>.customTextField>.inputRoot>input {
  border: none;
  padding-left: 0;
}

.confirmation-field>.customTextField>.inputRoot>input:focus {
  border: none;
}

.multi-select {
  font-size: 14px;
  margin: 15px 15px !important;
  color: #000;
  font-weight: 400;
  margin-top: 15px;
}

.confirm-deleting-text {
  font-size: 16px !important;
}
