.creatingNewUserNote {
  .modal-dialog {
    max-width: 780px;
    width: 100%;
    height: 100%;

    .modal-title {
      margin-left: 0px !important;
      padding-left: 40px !important;

      svg {
        margin-right: 20px;
      }
    }

    .modal-body {
      margin-bottom: 20px !important;
    }

    .modal-footer {
      border-top: 1px solid #40424d !important;
      padding: 40px 62px 25px !important;
    }
  }
}