.usr-default-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  line-height: 3rem;
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #3fc5dd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.usr-default-avatar.medium {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.usr-default-avatar.big {
  width: 135px;
  height: 135px;
  line-height: 135px;
  font-size: 80px;
  font-weight: 400;
}

.usr-default-avatar.small {
  min-width: 39px;
  width: 39px;
  height: 39px;
  line-height: 39px;
  font-size: 24px;
  font-weight: 400;
}

.company-logo {
  width: 145px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.logo-default {
    color: #5e6774;
    text-align: center;
    line-height: 45px;
    border: 1px solid #5e6774;
    font-size: 16px;
    font-weight: 500;
  }
}