$bg-color: #fff;
$today-color: #f6a36c;
$last-7-days-color: #6b6a92;
$year-color: #b0d5c3;
$circle-marker-color: #fff;
$circle-marker-bg-color: #d9d9d9;
$title-light-color: #000;
$value-light-color: #000;
$dark-light-color: #d6d8d5;

$status-ok-color: #1bbc9b;
$status-installation-ongoing-color: #3998f9;
$status-connected-color: #4ccd37;
$status-warning-color: #ff9e22;
$status-error-color: #f66400;
$status-not-connected-color: #db422d;

@mixin set-responsive-property($lg, $lx, $xl-2, $value: padding) {
  #{$value}: $lg;

  @media (min-width: 1280px) {
    #{$value}: $lx;
  }

  @media (min-width: 1760px) {
    #{$value}: $xl-2;
  }
}

@mixin adaptive-value($start, $finish, $property: font-size) {
  $addSize: $finish - $start;
  $container: 1920;
  @media (min-width: #{1024 + px}) {
    #{$property}: $start + px;
    #{$property}: calc(#{$start + px} + #{$addSize * 0.7} * (100vw / #{$container}));
  }
  @media (min-width: #{$container + px}) {
    #{$property}: $finish + px;
  }
}
