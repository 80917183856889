.cancel-button {
  padding: 0.6rem 2rem;
  border: 1px solid var(--primary-color);
  background: transparent;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 48px;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  text-transform: none;

  &:hover:hover:not(:disabled):not(.active) {
    background: #aed17c;
    box-shadow: none;
    text-decoration: none;
    cursor: pointer;
    color: var(--primary-color);
    transition: none;
  }
}