.display-none {
  display: none !important;
}

span.thank_you_email {
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.thank_you_email-content {
  min-width: 100%;
}

.thank_you_email-container {
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(75px);
  border-radius: 24px;
  padding: 72px 172px;
  width: fit-content;
  max-height: 500px;
  margin: 7% auto 0 auto;

  .btn-upd-dashb {
    margin-bottom: 5%;
  }
}