$datepicker-main-font-family: Gotham;
$datepicker-main-bg: #fff;
$datepicker-main-font-color: #000;
$datepicker-item-hover-bg-color: #f0f0f0;
$datepicker-item-disabled-bg-color: #f8f8f8;
$datepicker-item-disabled-color: #c6c8d2;
$datepicker-item-active-font-color: #fff;
$datepicker-item-active-bg-color: var(--primary-color);
$datepicker-item-days-color: #4a4a4a;
$datepicker-button-color: #c6c8d2;
$datepicker-selected-text-color: #212529;

.custom-datepicker-container {
  width: 100%;
}

.ant-picker {
  position: absolute;
  overflow: hidden;
  height: 0;
  width: 0;
  padding: 0;
  border: none;
}

/* Datepicker */
.ant-picker-dropdown {
  top: 0 !important;
  left: 0 !important;
  outline: none;

  .ant-picker-panel-layout {
    background-color: $datepicker-main-bg;
  }

  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-panel,
  .ant-picker-content {
    width: 100% !important;
  }

  .ant-picker-week-panel,
  .ant-picker-panel-container {
    background-color: $datepicker-main-bg;
  }

  .ant-picker-body {
    padding: 0 !important;
  }

  .ant-picker-panel,
  .ant-picker-panel-focused {
    width: 100%;
    border: none;
  }

  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-month-panel,
  .ant-picker-year-panel,
  .ant-picker-decade-panel,
  .ant-picker-footer {
    width: 100%;
  }

  .ant-picker-week-panel,
  .ant-picker-date-panel {
    .ant-picker-cell {
      min-height: 49px;
      height: 49px !important;
    }
  }

  .ant-picker-week-panel,
  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-month-panel {
    .ant-picker-cell {
      min-height: 92px;
      height: 92px;
    }
  }

  .ant-picker-header,
  .ant-picker-body {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    color: rgba(0, 0, 0, 0);
  }

  .ant-picker-content {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th {
      font-family: $datepicker-main-font-family;
      font-size: 17px;
      font-weight: 500;
      letter-spacing: 0.51px;
      color: #8F968E;
      text-transform: uppercase;
      text-align: center;
    }

    th,
    td {
      width: calc(100% / 7);
    }
  }

  .ant-picker-header {
    margin-bottom: 14px;
    background-color: #f8f8f8;
  }

  .empty-cell,
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn {
    min-width: 51px !important;
    min-height: 55px !important;
    line-height: 55px !important;
    margin: 0;
    padding: 0;
    font-family: $datepicker-main-font-family !important;
    font-size: 23px !important;
    font-weight: 400 !important;
    color: $datepicker-main-font-color !important;
    background: transparent;
    border: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $datepicker-item-hover-bg-color !important;
      color: $datepicker-item-active-font-color !important;
    }
  }

  .ant-picker-header-view {
    width: 100%;
    height: auto;
    color: $datepicker-main-font-color !important;
    font-size: 23px !important;
    font-weight: 400 !important;
    line-height: 55px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-picker-decade-btn,
  .ant-picker-year-btn,
  .ant-picker-month-btn {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    color: $datepicker-main-font-color;
    font-family: $datepicker-main-font-family;
    font-size: 23px;
    font-weight: 400;
    letter-spacing: 0.69px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $datepicker-item-hover-bg-color !important;
      color: $datepicker-item-active-font-color !important;
    }
  }

  .ant-picker-month-btn+.ant-picker-year-btn {
    margin: 0;
  }

  .ant-picker-cell-week,
  .ant-picker-cell {
    padding: 0;
    margin: 0;
    font-size: 21px;
    font-weight: 400;
    color: $datepicker-main-font-color;
    border: none;

    &::before {
      height: 48px;
    }

    &:hover:not(.ant-picker-cell-disabled) {
      // border-radius: 6px;
      overflow: hidden;
    }

    .ant-picker-cell-inner {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px !important;
      letter-spacing: 0.63px;
    }
  }

  .ant-picker-cell-in-view {
    color: $datepicker-main-font-color;
  }

  .ant-picker-cell-selected:not(.ant-picker-cell-today) {
    border-radius: 6px;
    overflow: hidden;
  }

  .ant-picker-cell-disabled {
    background-color: $datepicker-item-disabled-bg-color !important;
    color: $datepicker-item-disabled-color !important;

    &::before {
      background-color: $datepicker-item-disabled-bg-color !important;
    }
  }

  .ant-picker-week-panel-row.ant-picker-week-panel-row-selected {
    td.ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view {
      .ant-picker-cell-inner {
        color: $datepicker-item-disabled-color !important;
      }
    }
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-disabled):not(.ant-picker-cell-today),
  .ant-picker-cell-today:hover:not(.ant-picker-cell-disabled):not(.ant-picker-cell-selected) {
    background-color: $datepicker-item-hover-bg-color !important;
    color: $datepicker-selected-text-color !important;

    .ant-picker-cell-inner {
      background-color: $datepicker-item-hover-bg-color !important;
      color: $datepicker-selected-text-color !important;
    }
  }

  .ant-picker-week-panel-row,
  .ant-picker-week-panel-row-selected {

    .ant-picker-cell:hover:not(.ant-picker-cell-disabled):not(.ant-picker-cell-today),
    .ant-picker-cell-today:hover:not(.ant-picker-cell-disabled):not(.ant-picker-cell-selected) {
      background-color: transparent !important;
      color: transparent !important;

      .ant-picker-cell-inner {
        background-color: transparent !important;
        color: $datepicker-main-font-color !important;
      }
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      overflow: hidden !important;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff !important;
    background: linear-gradient(257deg, #9AB20F 0%, #4E7818 100%) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    background-color: $datepicker-item-disabled-bg-color !important;
    color: $datepicker-item-active-bg-color;

    &::before {
      border: none !important;
    }
  }

  .ant-picker-cell {

    &:not(.ant-picker-cell-disabled),
    &.ant-picker-cell-today:not(.ant-picker-cell-selected) {
      cursor: pointer;

      .ant-picker-cell-inner {
        cursor: pointer;
      }
    }

    &.ant-picker-cell-disabled,
    &.ant-picker-cell-selected {
      cursor: default;

      .ant-picker-cell-inner {
        cursor: default;
        overflow: hidden;
      }
    }
  }

  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-month-panel {
    .ant-picker-cell.ant-picker-cell-selected {
      cursor: pointer;

      .ant-picker-cell-inner {
        cursor: pointer;
      }
    }
  }

  /* Footer */
  .ant-picker-footer {
    border: none;

    .ant-picker-today-btn {
      color: $datepicker-item-active-bg-color !important;
    }
  }

  .ant-picker-week-panel-row-selected td.ant-picker-cell:before {
    color: $datepicker-item-active-bg-color !important;
    background: $datepicker-item-active-bg-color;
    opacity: 0.5;
  }

  .ant-picker-week-panel-row-selected td.ant-picker-cell-week {
    // border-radius: 10px !important;

    &:before {
      background: $datepicker-item-active-bg-color !important;
      opacity: 1;
    }
  }

  .ant-picker-week-panel-row {
    .ant-picker-cell-week {
      overflow: hidden;
      border-right: 3px solid var(--primary-color);

      &::before {
        border-radius: 10px !important;
      }
    }

    td:nth-child(2) {
      border-bottom-left-radius: 10px !important;
      border-top-left-radius: 10px !important;
      overflow: hidden;
    }

    td:last-child {
      border-bottom-right-radius: 10px !important;
      border-top-right-radius: 10px !important;
      overflow: hidden;
    }

    &:hover {
      td:before {
        overflow: hidden;
        background: $datepicker-item-active-bg-color;
        opacity: 0.3;

        &.ant-picker-cell-inner {
          opacity: 1;
        }
      }
    }
  }

  .empty-cell {
    font-size: 16px !important;
    position: absolute;
    color: $datepicker-item-active-bg-color !important;
    top: 59px;
    left: 10px;
  }
}
