@import "../../styles/source";

.widget-wrapper {
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  background-color: $bg-color;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  .widget-header {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $circle-marker-bg-color;
    padding-bottom: 5px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .widget-icon {
      height: 45px;
    }

    .widget-name {
      padding-left: 10px;
      font-weight: 300;
      color: $title-light-color;
      font-size: 16px;
    }

    .widget-total {
      color: $title-light-color;
      margin-right: 5px;
      font-weight: 300;
      font-size: 16px;
    }

    .widget-value {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .value {
    color: $value-light-color;
    font-weight: 700;

    span {
      font-weight: 400;
    }
  }

  .widget-content {
    justify-content: center;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    gap: 10px;

    .widget-content-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .widget-label {
        font-weight: 400;
        color: $value-light-color;
        @include adaptive-value(12, 14);
      }

      .value {
        @include adaptive-value(12, 14);
        @include set-responsive-property(500, 500, 700, font-weight);
      }
    }
  }

  .widget-content-v2x {
    display: flex;
    width: 90%;
    padding-top: 5px;
    gap: 5px;

    .vertical-line {
      width: 1px;
      background-color: $circle-marker-bg-color;
      height: auto;
    }

    .widget-content-column {
      width: 50%;

      .widget-column-title {
        font-weight: 400;
        color: rgba($color: $value-light-color, $alpha: 0.5);

        @include adaptive-value(12, 14);
      }

      .widget-content-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .widget-label {
          font-weight: 400;
          color: $value-light-color;
          white-space: nowrap;

          @include adaptive-value(10, 14);
        }

        .value {
          @include set-responsive-property(400, 500, 700, font-weight);
          @include adaptive-value(10, 14);
        }
      }
    }
  }
}