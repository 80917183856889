.dataExportSubMeterModal,
.dataExportModal,
.calendarModalId {
  .modalContainer {
    min-width: 1058px;

    .modal-dialog {
      max-width: 1058px;

      .modal-title {
        width: 100%;
        text-align: center;

        i.la.la-download.download-icon {
          color: #000;
        }

        span {
          color: #000;
        }
      }

      .modal-body {
        width: 100%;
        height: 100%;

        .export-data-datepicker-container {
          border-radius: 9px;
          overflow: hidden;
          box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.5);

          th {
            font-size: 14px;
            letter-spacing: 0.42px;
            border-bottom: 10px solid transparent;
          }

          .ant-picker-header-super-prev-btn,
          .ant-picker-header-prev-btn,
          .ant-picker-header-next-btn,
          .ant-picker-header-super-next-btn {
            min-width: 43px;
            min-height: 46px;
            line-height: 46px;
            font-size: 20px;
            letter-spacing: 0.6px;
          }

          .ant-picker-header-view {
            font-size: 20px;
            letter-spacing: 0.6px;
            line-height: 46px;
          }

          .ant-picker-decade-btn,
          .ant-picker-year-btn,
          .ant-picker-month-btn {
            font-size: 20px;
            letter-spacing: 0.6px;
          }

          .ant-picker-date-panel {
            .ant-picker-cell {
              min-height: 42px;
              height: 42px;
            }
          }

          .ant-picker-decade-panel,
          .ant-picker-year-panel,
          .ant-picker-month-panel {
            .ant-picker-cell {
              min-height: 71px;
              height: 71px;
            }
          }

          .ant-picker-header {
            margin-bottom: 5px;
          }

          .ant-picker-cell {
            font-size: 18px;
            letter-spacing: 0.54px;

            &::before {
              height: 46px;
            }

            .ant-picker-cell-inner {
              font-size: 18px;
              letter-spacing: 0.54px;
            }
          }

          .ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background-color: var(--primary-color);
              color: #1f2126;
              border-radius: 6px;
            }

            &:hover,
            &:focus {
              .ant-picker-cell-inner {
                background-color: #c6c8d2;
                color: #1f2126;
              }
            }
          }
        }

        .date-input-label {
          font-size: 24px;
          font-weight: 400;
          color: #000;
          letter-spacing: 0;
        }

        .export-all-client-data-info {
          margin-top: 48px;
          margin-bottom: 29px;

          span {
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 32px;
            color: #000;
          }
        }

        .datepicker-col {
          width: 455px;

          &:first-of-type {
            margin-right: 9px;
          }
        }

        .datepicker-input-field {
          width: 145px;
          height: 60px;
          margin-left: 15px;

          &-container {
            margin-bottom: 21px;

            & legend {
              display: none;
            }
          }
        }
      }

    }
  }
}

@media (max-width: 1400px) {
  .date-input-label {
    font-size: 18px;
    padding-top: 5px;
  }

  .dataExportSubMeterModal,
  .dataExportModal {
    .modalContainer {
      min-width: 900px;

      .modal-dialog {
        max-width: 900px;
      }
    }
  }

  .export-data-datepicker-container {
    .ant-picker-date-panel {
      .ant-picker-cell {
        min-height: 35px;
        height: 35px;
      }
    }
  }

  .export-all-client-data-info {
    margin-top: 35px;
    margin-bottom: 20px;

    span {
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
    }
  }
}