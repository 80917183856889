@keyframes showNoAccessWarning {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.noAccessWarning {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: showNoAccessWarning;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 1s;
  animation-fill-mode: both;
}